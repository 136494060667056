<template>
  <div class="is-block is-full-width">
    <div class="controls is-pulled-right" v-if="showTopButtons">
      <button
        class="button is-primary white"
        @click.stop="openUploadModal"
        cy-data="document-upload"
      >
        <i class="fa fa-upload"></i>&nbsp;
        {{ $t("common.upload") }}
      </button>
    </div>
    <div class="view">
      <flexi-table
        class="is-full-width"
        :loader="fetchDocuments"
        :columns="documentTableColumns"
        :showIndex="true"
        v-model="documents"
        ref="documentsTable"
        :hasActions="false"
      >
        <template v-slot:dataRows="{ item, index }">
          <tr>
            <td>{{ index + 1 }}</td>
            <td>{{ item.filename }}</td>
            <td>{{ item.type && item.type.name }}</td>
            <td>{{ item.createdAt | reverseDate }}</td>
            <td>
              <div class="is-pulled-right">
                <form class="inline-form" method="POST" target="_blank" :action="`${API}/document/${item.id}`">
                  <button class="button is-success is-small" type="submit">
                    <i class="fa fa-download"></i>&nbsp;
                    {{ $t('common.download') }}
                  </button>
                  <input type="hidden" name="token" :value="authToken">
                </form>
                <button v-if="deletable" class="button is-danger is-small" @click="deleteDocument(item)">
                  <i class="fa fa-trash"></i>
                </button>
                <!-- <router-link :to="'/document/' + item.id" class="button is-primary is-small" v-if="viewButton">
                  <i class="fa fa-search"></i>
                </router-link> -->
              </div>
            </td>
          </tr>
        </template>
      </flexi-table>
    </div>
    <modal
      ref="createDocumentModal"
      @create="uploadDocument"
      @close="closeModal"
      :loading="isUploading"
      :title="$t('expressions.addDocument')"
    >
      <div class="field">
        <button class="button is-primary" @click.stop="addFile">
          <i class="fa fa-choose"></i>&nbsp;
          {{ $t("expressions.selectFile") }}

        </button>
      </div>
      <field readonly inline :label="$t('expressions.selectedFileName')">{{
        selectedFileName
      }}</field>
      <input
        class="hidden"
        ref="documentFileInput"
        type="file"
        name="documentFile"
        accept="*/*"
        cy-data="document-file-input"
        @change="selectFile"
      />
      <many2one-field
        :label="$t('entities.documentType')"
        v-model="createDocument.documentType"
        :fetch="$api.fetchDocumentTypes"
        reference="name"
        :columns="documentTypeMany2OneColumns"
        :edit="true"
        cy-data="document-upload-type"
      ></many2one-field>
    </modal>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "document-manager",
  props: {
    objectName: String,
    objectId: [String, Number],
    deletable: {
      type: Boolean,
      default: false,
    },
    showTopButtons: {
      type: Boolean,
      default: true,
    },
    viewButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      documents: [],
      createDocument: {
        documentType: null,
        file: null,
      },
      API: axios.defaults.baseURL,
      authToken: this.$store.getters["auth/getToken"],
      documentTypeMany2OneColumns: {
        name: this.$t("common.name"),
      },
      isUploading: false,
    };
  },
  computed: {
    ...mapGetters({
      getRights: "auth/getRights",
    }),
    selectedFileName() {
      return this.createDocument.file ? this.createDocument.file.name : " ";
    },
    documentTableColumns() {
      const columns = [
        {
          title: this.$t("expressions.fileName"),
          name: "filename",
          accessor: "filename",
          filter: "",
        },
        {
          title: this.$t("common.type"),
          name: "type",
          accessor: "type.name",
          filter: "",
        },
        {
          title: this.$t("common.date"),
          name: "date",
          accessor: "createdAt",
          filter: "",
        },
      ];
      return columns;
    },
  },
  methods: {
    async fetchDocuments(callback) {
      try {
        const { data } = await axios.get(
          `/documents?entity=${this.objectName}&id=${this.objectId}`
        );
        if (typeof callback === "function") {
          callback(data);
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    },
    async uploadDocument() {
      const { documentType, file } = this.createDocument;
      if (!documentType || !file) {
        alert(this.$t("expressions.noFileSelected"));
        return;
      }

      this.isUploading = true;
      const form = new FormData();
      form.append("file", file, file.name);
      form.append("entity", this.objectName);
      form.append("id", this.objectId);
      form.append("type", documentType.id);

      try {
        await axios.post("document", form);
        this.$refs.createDocumentModal.close();
      } catch (error) {
        console.error("Error uploading document:", error);
      } finally {
        this.isUploading = false;
        this.$refs.documentsTable.fetch();
      }
    },
    openUploadModal() {
      this.$refs.createDocumentModal.open();
    },
    addFile() {
      this.$refs.documentFileInput.click();
    },
    selectFile() {
      this.createDocument.file = this.$refs.documentFileInput.files[0];
    },
    closeModal() {
      this.createDocument = {
        documentType: null,
        file: null,
      };
    },
    async deleteDocument(document) {
      try {
        await this.$awn.confirm(
          this.$t("expressions.deleteDocument"),
          async () => {
            const response = await axios.delete(`/document/${document.id}`);
            if (response.data.success) {
              this.$refs.documentsTable.fetch();
            }
          }
        );
      } catch (error) {
        console.error("Error deleting document:", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.controls .button {
  margin-left: 4px;
}

.is-full-width {
  width: 100%;
  flex: 1;
}

.white {
  color: #fff;
}

.field {
  margin-bottom: 0.75em;
}

.inline-form {
  display: inline;
}

td .is-pulled-right > * {
  margin-left: 4px;
}

.hidden {
  display: none;
}

.help {
  display: inline-block;
  color: white;
  background-color: #019fc4;
  border-radius: 50%;
  padding: 0 5px;
  cursor: pointer;
  margin-left: 5px;
}
</style>
